import { Transition } from '@headlessui/react';
import { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import classNames from 'classnames';

type ArrowPositionType = '-bottom-1' | '-left-1' | '-right-1' | '-top-1';

type Props = {
  label: String;
  placement: Placement;
  arrowPosition: ArrowPositionType;
  children: React.ReactNode;
};

export default function Tooltip({
  label,
  children,
  placement,
  arrowPosition,
}: Props) {
  const popperElRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 3],
        },
      },
    ],
  });

  const showTooltip = () => setOpen(true);
  const disableTooltip = () => setOpen(false);

  return (
    <>
      <button
        ref={setTargetElement}
        onMouseOver={showTooltip}
        onMouseOut={disableTooltip}
      >
        {label}
      </button>

      <div
        ref={popperElRef}
        className={'z-10'}
        style={styles.popper}
        {...attributes.popper}
      >
        <Transition
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          beforeEnter={() => setPopperElement(popperElRef.current)}
          afterLeave={() => setPopperElement(null)}
          className="bg-gray-dark500 font-semibold py-2 px-4 rounded-md text-xs "
        >
          {children}

          <div
            ref={setArrowElement}
            style={styles.arrow}
            className={classNames('', arrowPosition)}
          >
            <div className="w-2 h-2 bg-gray-dark500 rotate-45" />
          </div>
        </Transition>
      </div>
    </>
  );
}

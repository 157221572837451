import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { PowerDistributionUnitBillData } from 'powerDistributionUnitBillData/models/powerDistributionUnitBillData';

import { MdDownload } from 'react-icons/md';
import { Tooltip } from 'ui/components/tooltip';

type Props = {
  powerDistributionUnitBillDatas: PowerDistributionUnitBillData;
};

const BILL_DATA_DATE_FORMAT_CONFIG = {
  locale: 'pt-BR',
};

const formatDate = (date: Date) =>
  new Intl.DateTimeFormat(BILL_DATA_DATE_FORMAT_CONFIG.locale).format(
    new Date(date)
  );

export default function PowerDistributionUnitBillDatasTableItem({
  powerDistributionUnitBillDatas: {
    id,
    billReferenceDate,
    consumerUnit: {
      consumerUnitPowerDistributionUnitIdentifier,
      cooperative: { cooperativeLegalName },
      cooperativeMember: { cooperativeMemberLegalName },
    },
    billIssueDate,
    billConsumerUnitConsumptionGroupType,
    powerDistributionUnit: { powerDistributionUnitLegalName },
    billConsumedEnergyCredits: {
      consumedEnergyCreditsOffPeak,
      consumedEnergyCreditsOnPeak,
      consumedEnergyCreditsTotal,
    },
    billConsumedEnergyKwh: {
      consumedEnergyKwhOffPeak,
      consumedEnergyKwhOnPeak,
      consumedEnergyKwhTotal,
    },
  },
}: Props) {
  const issueDateFormatted = formatDate(billIssueDate);
  const referenceDateFormatted = formatDate(billReferenceDate);

  return (
    <TableBodyRow dataTestId={`power-distribution-unit-bill-data-row-id-${id}`}>
      <TableBodyColumn>{referenceDateFormatted}</TableBodyColumn>
      <TableBodyColumn>{cooperativeMemberLegalName}</TableBodyColumn>
      <TableBodyColumn>
        {consumerUnitPowerDistributionUnitIdentifier}
      </TableBodyColumn>
      <TableBodyColumn>{issueDateFormatted}</TableBodyColumn>
      <TableBodyColumn>{cooperativeLegalName}</TableBodyColumn>
      <TableBodyColumn>{powerDistributionUnitLegalName}</TableBodyColumn>
      <TableBodyColumn className="font-bold">
        <Tooltip
          label={consumedEnergyKwhTotal}
          placement={'bottom'}
          arrowPosition={'-top-1'}
        >
          <p>Grupo {billConsumerUnitConsumptionGroupType}</p>

          {billConsumerUnitConsumptionGroupType === 'A' ? (
            <>
              <p>FP: {consumedEnergyKwhOffPeak}</p>
              <p>PTA: {consumedEnergyKwhOnPeak}</p>
            </>
          ) : (
            <p>FP: {consumedEnergyKwhOffPeak}</p>
          )}
        </Tooltip>
      </TableBodyColumn>

      <TableBodyColumn className="font-bold">
        <Tooltip
          label={consumedEnergyCreditsTotal}
          placement={'bottom'}
          arrowPosition={'-top-1'}
        >
          <p>Grupo {billConsumerUnitConsumptionGroupType}</p>

          {billConsumerUnitConsumptionGroupType === 'A' ? (
            <>
              <p>FP: {consumedEnergyCreditsOffPeak}</p>
              <p>PTA: {consumedEnergyCreditsOnPeak}</p>
            </>
          ) : (
            <p>FP: {consumedEnergyCreditsOffPeak}</p>
          )}
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <MdDownload size={23} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}

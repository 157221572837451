import { MenuSidebar } from 'dashboard/models/navigation';
import {
  MdAccountBalance,
  MdHome,
  MdAccountBalanceWallet,
  MdWifiTethering,
  MdAssignmentInd,
  MdStore,
  MdOutlineBookmark,
  MdFlag,
  MdOutlineSignalCellularAlt,
  MdFolderShared,
  MdAssignment,
  MdStyle,
  MdWatchLater,
} from 'react-icons/md';

import { ImHammer2 } from 'react-icons/im';
import { HiLightBulb, HiOutlineAdjustments } from 'react-icons/hi';
import { RiCalendar2Fill, RiSwapFill } from 'react-icons/ri';

export const menuSidebar: MenuSidebar = [
  {
    name: 'Usuário interno',
    subtitle: 'USUÁRIO INTERNO',
    to: '/dashboard/internal-users',
    icon: MdHome,
  },
  {
    name: 'Entidades de negócio',
    subtitle: 'ENTIDADES DE NEGÓCIO',
    icon: MdAccountBalance,
    submenu: [
      {
        name: 'Usinas',
        to: '/dashboard/business-entities/power-plants',
        icon: MdWifiTethering,
      },
      {
        name: 'Cooperativa',
        to: '/dashboard/business-entities/cooperatives',
        icon: MdAccountBalance,
      },
      {
        name: 'Cooperado',
        to: '/dashboard/business-entities/cooperative-members',
        icon: MdAssignmentInd,
      },
      {
        name: 'Unidade consumidora',
        to: '/dashboard/business-entities/consumer-units',
        icon: MdStore,
      },
      {
        name: 'Concessionária',
        to: '/dashboard/business-entities/power-distribution-units',
        icon: HiLightBulb,
      },
    ],
  },
  {
    name: 'Faturamento NEX',
    subtitle: 'FATURAMENTO NEX',
    icon: MdAccountBalanceWallet,
    submenu: [
      {
        name: 'Rateio',
        to: '/dashboard/billing-cycle/apportionments',
        icon: HiOutlineAdjustments,
      },
      {
        name: 'Cobranças',
        to: '/dashboard/billing-cycle/charges',
        icon: RiSwapFill,
      },
      {
        name: 'Creditação',
        to: '/dashboard/billing-cycle/bill-data',
        icon: MdStyle,
      },
      {
        name: 'Ciclos de locação',
        to: '/dashboard/billing-cycle/lease-cycles',
        icon: MdWatchLater,
      },
      {
        name: 'Transferências',
        to: '/dashboard/billing-cycle/transfers',
        icon: MdAssignment,
      },
      {
        name: 'Conta financeira',
        to: '/dashboard/billing-cycle/financial-accounts',
        icon: MdFolderShared,
      },
    ],
  },
  {
    name: 'Tarifas e tributos',
    subtitle: 'TARIFAS E TRIBUTOS',
    icon: ImHammer2,
    submenu: [
      {
        name: 'Tarifas de energia',
        to: '/dashboard/taxes-fees/energy-tariffs',
        icon: MdOutlineBookmark,
      },
      {
        name: 'Bandeiras',
        to: '/dashboard/taxes-fees/tariff-flags',
        icon: MdFlag,
      },
      {
        name: 'ICMS',
        to: '/dashboard/taxes-fees/icms-taxes',
        icon: MdOutlineSignalCellularAlt,
      },
      {
        name: 'PIS/COFINS',
        to: '/dashboard/taxes-fees/pis-cofins-taxes',
        icon: RiCalendar2Fill,
      },
    ],
  },
];

import { Switch, Route } from 'react-router-dom';

import { LoginPage, NewPasswordPage } from 'auth/pages/login';
import {
  ListInternalUsersPage,
  CreateInternalUsersPage,
  EditInternalUsersPage,
} from 'internalUsers/pages';
import {
  ListPowerDistributionUnitsPage,
  CreatePowerDistributionUnitsPage,
  EditPowerDistributionUnitsPage,
  ListPowerDistributionUnitTariffGroups,
} from 'powerDistributionUnits/pages';
import {
  CreateCooperativesPage,
  ListCooperativesPage,
  EditCooperativesPage,
  ListCooperativeMembersPage,
  CreateCooperativeMembersPage,
  EditCooperativeMembersPage,
} from 'cooperatives/pages';
import {
  ListPisCofinsTaxesPage,
  CreatePisCofinsTaxesPage,
  EditPisCofinsTaxesPage,
} from 'pisCofinsTaxes/pages';
import {
  ListIcmsTaxesPage,
  CreateIcmsTaxesPage,
  EditIcmsTaxesPage,
} from 'icmsTaxes/pages';
import { UiDashboardPage } from 'uiDashboard/pages';
import { Dashboard } from 'dashboard/components/dashboard';
import {
  ListConsumerUnitsPage,
  CreateConsumerUnitsPage,
  EditConsumerUnitsPage,
} from 'consumerUnits/pages';
import {
  ListFinancialAccountsPage,
  CreateFinancialAccountsPage,
  EditFinancialAccountsPage,
} from 'financialAccounts/pages';
import { ListPowerDistributionUnitBillDatasPage } from 'powerDistributionUnitBillData/pages';
import PrivateRoute from './PrivateRoute';

const Routes = () => (
  <Switch>
    <Route path="/dashboard/ui" component={UiDashboardPage} />

    <PrivateRoute
      path="/dashboard/internal-users/create"
      component={CreateInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/internal-users/:id/edit"
      component={EditInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/internal-users"
      component={ListInternalUsersPage}
    />

    <PrivateRoute
      path="/dashboard/business-entities/cooperatives/create"
      component={CreateCooperativesPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/cooperatives/:id/edit"
      component={EditCooperativesPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/cooperatives"
      component={ListCooperativesPage}
    />

    <PrivateRoute
      path="/dashboard/business-entities/cooperative-members/create"
      component={CreateCooperativeMembersPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/cooperative-members/:id/edit"
      component={EditCooperativeMembersPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/cooperative-members"
      component={ListCooperativeMembersPage}
    />

    <PrivateRoute
      path="/dashboard/business-entities/consumer-units/create"
      component={CreateConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/consumer-units/:id/edit"
      component={EditConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/consumer-units"
      component={ListConsumerUnitsPage}
    />

    <PrivateRoute
      path="/dashboard/business-entities/power-distribution-units/create"
      component={CreatePowerDistributionUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/power-distribution-units/:id/edit"
      component={EditPowerDistributionUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/power-distribution-units"
      component={ListPowerDistributionUnitsPage}
    />

    <PrivateRoute
      path="/dashboard/billing-cycle/bill-data"
      component={ListPowerDistributionUnitBillDatasPage}
    />

    <PrivateRoute
      path="/dashboard/billing-cycle/financial-accounts/create"
      component={CreateFinancialAccountsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/financial-accounts/:id/edit"
      component={EditFinancialAccountsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/financial-accounts"
      component={ListFinancialAccountsPage}
    />

    <PrivateRoute
      path="/dashboard/taxes-fees/energy-tariffs"
      component={ListPowerDistributionUnitTariffGroups}
    />

    <PrivateRoute
      path="/dashboard/taxes-fees/icms-taxes/create"
      component={CreateIcmsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/icms-taxes/:id/edit"
      component={EditIcmsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/icms-taxes"
      component={ListIcmsTaxesPage}
    />

    <PrivateRoute
      path="/dashboard/taxes-fees/pis-cofins-taxes/create"
      component={CreatePisCofinsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/pis-cofins-taxes/:id/edit"
      component={EditPisCofinsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/pis-cofins-taxes"
      component={ListPisCofinsTaxesPage}
    />

    <PrivateRoute path="/dashboard" component={Dashboard} />
    <Route path="/sign-in/new-password" component={NewPasswordPage} />
    <Route path="/" component={LoginPage} />
  </Switch>
);

export default Routes;
